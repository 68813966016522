<template>
  <v-tabs
    class="mt-3"
    centered
    color="primary"
    next-icon="fa-chevron-right"
    prev-icon="fa-chevron-left"
    show-arrows
    :icons-and-text="$vuetify.breakpoint.smAndUp"
    v-model="activeTab"
  >
    <v-tab v-for="tab in tabs" :key="tab.id" :href="tab.route">
      <span
        v-if="$vuetify.breakpoint.smAndUp"
        class="mt-2"
        v-text="$tc(tab.name, 2)"
      ></span>
      <v-img
        v-if="tab.name === 'dashboard'"
        class="mx-auto"
        height="35"
        width="35"
        :src="require('@/assets/color-icons/dashboard/dashboard.png')"
      >
      </v-img>
      <v-img
        v-if="tab.name === 'list'"
        class="mx-auto"
        height="35"
        width="35"
        :src="require('@/assets/color-icons/dashboard/list.png')"
      >
      </v-img>
      <v-img
        v-if="tab.name === 'calendar'"
        class="mx-auto"
        height="35"
        width="35"
        :src="require('@/assets/color-icons/dashboard/calendar.png')"
      >
      </v-img>
      <v-icon :medium="$vuetify.breakpoint.smAndUp" v-text="tab.icon" dark />
    </v-tab>
    <v-tabs-items v-model="activeTab">
      <v-tab-item value="tab-dashboard">
        <orders v-if="activeTab === 'tab-dashboard'" />
      </v-tab-item>
      <v-tab-item value="tab-list">
        <list v-if="activeTab === 'tab-list'" />
      </v-tab-item>
      <v-tab-item value="tab-calendar">
        <calendar v-if="activeTab === 'tab-calendar'" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import orders from '@/components/dashboard/orders.vue'
import calendar from '@/components/dashboard/calendar.vue'
import list from '../pages/sale/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'dashboard',
  components: {
    orders,
    list,
    calendar
  },
  data() {
    return {
      loading: false,
      activeTab: 'tab-dashboard',
      firstCompany: 0,
      tabs: [
        {
          id: 1,
          name: 'dashboard',
          route: '#tab-dashboard'
        },
        {
          id: 2,
          name: 'list',
          route: '#tab-list'
        },
        {
          id: 3,
          name: 'calendar',
          route: '#tab-calendar'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      myCompany: 'company/getCompanyData'
    })
  },
  methods: {
    async getSettingInfo() {
      this.loading = true
      if (this.myCompany && this.myCompany !== undefined) {
        this.firstCompany = this.myCompany.pk
        const company = await this.$api.company.show({
          pk: this.firstCompany
        })
        this.activeTab = company.data.dashboard
      }
      this.loading = false
    }
  },
  mounted() {
    this.getSettingInfo()
  }
}
</script>
